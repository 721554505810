import React from "react";
import Bg from "../generativeart";

const Home = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li>
              <a href="/about">about</a>
            </li>
            <li>
              <a href="/posts">posts</a>
            </li>
            <li>
              <a href="/projects">projects</a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="intro">
        <div className="intro-wrapper">
          <div className="intro-p">
            <h1>
              Varinder <br></br> Singh
            </h1>
            <p>
              I'm a <b>full stack developer</b> who creates meaningful digital
              experiences for users worldwide. I am highly passionate about
              building <b>high performance</b> intuitive web applications based
              on industry standards.
            </p>
          </div>
          <div className="animation">
            <div>
              <Bg />
            </div>
            <div className="art-legend">
              <b>Click this art</b> to regenerate it.{" "}
              <button>
                [<b>?</b>]
              </button>
              <div className="tooltip">
                This art is randomly generated on every page refresh. You can
                also regenerate it by clicking on it. Every art is brand new!
              </div>
            </div>
          </div>
        </div>
        <div className="dots"></div>
      </div>
      <section className="content">
        <p>
          I have 6+ years of experience working with various organizations. My
          work has been primarily focused on <b>JavaScript</b>, <b>Typescript</b>, <b>React.js</b>,{" "}
          <b>Node.js</b>, <b>Java</b>, <b>Go</b>, <b>PHP</b> and <b>AWS</b>.
          Throughout my journey as a full stack developer, I have delved into
          diverse projects, ranging from crafting music apps and trading bots to
          exploring creative interactions with web components and web standards.
          You can read a bit more <a href="/about">about me</a> and my past
          work. Also, you can visit my{" "}
          <a
            href="https://github.com/varinderxyz"
            target="_blank"
            rel="noreferrer"
          >
            Github
          </a>
          .
        </p>
        <hr></hr>
        <h2>Writing</h2>
        <p>
          I recently started writing about things or technologies that I'm
          curious about. You can follow these post via{" "}
          <a href="https://medium.com/@varinderxyz">medium</a>
        </p>
      </section>
    </div>
  );
};

export default Home;
