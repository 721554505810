import React from "react";
import { FaHome } from "react-icons/fa";
import Bg from "../generativeart";
import { FaHandPeace } from "react-icons/fa";

const About = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li className="home_link">
              <a href="/">
                <FaHome />
              </a>
            </li>
            <li>
              <a href="/about">about</a>
            </li>
            <li>
              <a href="/posts">posts</a>
            </li>
            <li>
              <a href="/projects">projects</a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="intro">
        <div className="intro-wrapper">
          <div className="intro-p">
            <h1>
              About Me{" "}
              <span>
                <FaHandPeace />
              </span>
            </h1>
            <p></p>
          </div>
          <div className="animation">
            <div>
              <Bg />
            </div>
            <div className="art-legend">
              <b>Click this art</b> to regenerate it.{" "}
              <button>
                [<b>?</b>]
              </button>
              <div className="tooltip">
                This art is randomly generated on every page refresh. You can
                also regenerate it by clicking on it. Every art is brand new!
              </div>
            </div>
          </div>
        </div>
        <div className="dots"></div>
      </div>
      <div className="content">
        <p>
          I'm a <b>Full Stack Developer</b> with a demonstrated history of
          working in the Information Technology and Services Industry.
          Proficient in Full Stack development, adept at deploying a diverse set
          of technologies across various frameworks, libraries, and programming
          languages.
        </p>
        <p>
          I recently completed my stint at{" "}
          <a
            href="https://www.cqlsys.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            CLQsys
          </a>{" "}
          where I was developing various user facing modules such as deliveries,
          payouts for a healthy meals delivery application. I worked closely
          with product managers to gather requirements, provide technical
          insights, and delivered solutions aligned with business goals.
        </p>
        <p>
          I spent most of my 2022 doing more creative work and experimenting
          with new technologies. I always strived to do online biz kind of
          thing. Along with this, I was interested in crypto payments and
          familiarizing people with it. So, I developed{" "}
          <a
            href="https://github.com/varinderxyz/honey-road-frontend"
            target="_blank"
            rel="noopener noreferrer"
          >
            honeyroad
          </a>{" "}
          where you can buy honey using bitcoin which was procured from
          australian farmers. I also added{" "}
          <a
            href="https://lightning.network/"
            target="_blank"
            rel="noopener noreferrer"
          >
            lightning
          </a>{" "}
          support. I did some other cool projects in consulting capacity.
        </p>
        <p>
          Prior to that, I was working with{" "}
          <a
            href="https://unoiatech.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            unoiatech
          </a>{" "}
          where my work was mostly focused on creative applications and
          interactions. As I'm highly influenced by art and music. I worked on
          Artistic project{" "}
          <a
            href="http://guitarjedi.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Guitarjedi
          </a>{" "}
          which provides chord recognition/generation for any musical instrument
          with n-strings in any tuning. I was also involved in spearheading
          various marketplace applications which was an incredible experience.
        </p>
        <p>
          I also worked with India's leading ecommerce start-up{" "}
          <a
            href="https://www.snapdeal.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Snapdeal
          </a>{" "}
          as a <b>Front End Developer</b> where I mostly worked on user
          interfaces and visually appealing page designs.
        </p>
        <p>
          Before that, in the prehistoric times, I worked as a{" "}
          <b>PHP developer</b> which was my first job basically. I have a Post
          Graduate Diploma in Computer Applications from{" "}
          <a
            href="http://www.ignou.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            IGNOU
          </a>{" "}
          which I completed in 2021 . I did my Bachelor's degree in Electronics
          and Communication Engineering from{" "}
          <a
            href="https://www.kuk.ac.in/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kurukshetra University
          </a>
        </p>
      </div>
    </div>
  );
};

export default About;
