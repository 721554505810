import Sketch from "react-p5";

function Bg(props) {
  let N;
  let s,
    margin = 0.78;

  let detail = 25;

  let palette1, palette2;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(350, 350, p5.WEBGL).parent(canvasParentRef);
    N = p5.random([4, 6]);
    s = p5.width / (N + 2 * margin);
    margin *= s;
    p5.noStroke();
    p5.noLoop();
  };
  

  const mousePressed = (p5, event, canvasParentRef) => {
    ///defaultCanvas0
    if(event.target.id === "defaultCanvas0") {
      p5.redraw();
    }
    //p5.reset()
    //p5._draw()
  };

  const draw = (p5) => {
    p5.translate(-p5.width / 2, -p5.height / 2);

    palette1 = [
      "#abcd5e",
      "#14976b",
      "#2b67af",
      "#62b6de",
      "#f589a3",
      "#ef562f",
      "#fc8405",
      "#f9d531",
    ];
    palette2 = p5.shuffle(["#050505", "#fffbe6"]);

    let backCol = p5.random([0, 1]);
    p5.background("fffbe6");

    for (let i = 0; i <= N; i++) {
      let x = i * s + margin;
      for (let j = 0; j <= N; j++) {
        let y = j * s + margin;
        p5.fill(palette2[(i + j) % 2]);
        p5.ellipse(x, y, s, s, detail * 4);
      }
    }

    for (let i = 0; i < N; i++) {
      for (let j = 0; j < N; j++) {
        makeTile(i, j, p5);
      }
    }

    let dotMode = ~~p5.random(4);
    for (let i = 0; i <= N; i++) {
      let x = i * s + margin;
      for (let j = 0; j <= N; j++) {
        let y = j * s + margin;
        if (dotMode == 0) {
          p5.fill(p5.random(p5.random([palette1, palette2])));
        } else if (dotMode == 1) {
          p5.fill(p5.random(palette1));
        } else if (dotMode == 2) {
          p5.fill(p5.random(palette2));
        } else {
          p5.fill(palette2[1 - ((i + j) % 2)]);
        }
        if ((i + j) % 2 == backCol) p5.fill(p5.random(palette1));
        else p5.fill(palette2[1 - ((i + j) % 2)]);
        p5.ellipse(x, y, s / 2, s / 2, detail * 4);
      }
    }
  }

  function makeTile(i, j, p5) {

    let x = i * s + margin;
    let y = j * s + margin;
    if (p5.random() < 1/2) {
      p5.fill(p5.random(palette1));
      p5.square(x, y, s);
      p5.fill(palette2[(i + j) % 2]);
      p5.arc(x, y, s, s, 0, p5.PI / 2, p5.PIE, detail);
      p5.arc(x + s, y + s, s, s, p5.PI, (3 * p5.PI) / 2, p5.PIE, detail);
      p5.fill(palette2[1 - ((i + j) % 2)]);
      p5.arc(x + s, y, s, s, p5.PI / 2, p5.PI, p5.PIE, detail);
      p5.arc(x, y + s, s, s, (3 * p5.PI) / 2, p5.TAU, p5.PIE, detail);
    } else {
      if (p5.random() < 1 / 2) {
        p5.fill(palette2[1 - ((i + j) % 2)]);
        p5.square(x, y, s);
        p5.fill(palette2[(i + j) % 2]);
        p5.arc(x, y, s, s, 0, p5.PI / 2, p5.PIE, detail);
        p5.arc(x + s, y + s, s, s, p5.PI, (3 * p5.PI) / 2, p5.PIE, detail);
      } else {
        p5.fill(palette2[(i + j) % 2]);
        p5.square(x, y, s);
        p5.fill(palette2[1 - ((i + j) % 2)]);
        p5.arc(x + s, y, s, s, p5.PI / 2, p5.PI, p5.PIE, detail);
        p5.arc(x, y + s, s, s, (3 * p5.PI) / 2, p5.TAU, p5.PIE, detail);
      }
    }
  }

  return <Sketch setup={setup} draw={draw} mousePressed={mousePressed} />;
}
export default Bg;
