import React from "react";
import { FaHome } from "react-icons/fa";
import Bg from "../generativeart";

const Posts = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li className="home_link">
              <a href="/">
                <FaHome />
              </a>
            </li>
            <li>
              <a href="/about">about</a>
            </li>
            <li>
              <a href="/posts">posts</a>
            </li>
            <li>
              <a href="/projects">projects</a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="intro">
        <div className="intro-wrapper">
          <div className="intro-p">
            <h1>Posts </h1>
            <p></p>
          </div>
          <div className="animation">
            <div>
              <Bg />
            </div>
            <div className="art-legend">
              <b>Click this art</b> to regenerate it.{" "}
              <button>
                [<b>?</b>]
              </button>
              <div className="tooltip">
                This art is randomly generated on every page refresh. You can
                also regenerate it by clicking on it. Every art is brand new!
              </div>
            </div>
          </div>
        </div>
        <div className="dots"></div>
      </div>
      <div className="content">
        <p>
          You can follow these posts via{" "}
          <a href="https://medium.com/@varinderxyz">medium</a> where I share my
          thoughts on future technologies.
        </p>
        <hr />
        <h2 className="with-under">2023</h2>
        <ul className="listing fancy table">
          <li>
            <a
              target="_blank"
              href="https://medium.com/@varinderxyz/artificial-intelligence-and-its-impact-on-the-ev-industry-6d87ce59c85c"
              rel="noopener noreferrer"
            >
              Empowering the EV Industry through AI
            </a>
            <span>July 20, 2023</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Posts;
