
import './App.css';
import { Routes, Route } from "react-router-dom"
import Home from "./Components/Home"
import About from "./Components/About"
import Projects from "./Components/Projects"
import Posts from "./Components/Posts"

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="posts" element={<Posts />} />
        <Route path="projects" element={<Projects />} />
      </Routes>
    </div>
  );
}

export default App;
