import React from 'react'
import { FaHome } from "react-icons/fa";
import Bg from "../generativeart";

const Projects = () => {
  return (
    <div>
      <header>
        <nav>
          <ul>
            <li className="home_link">
              <a href="/">
                <FaHome />
              </a>
            </li>
            <li>
              <a href="/about">about</a>
            </li>
            <li>
              <a href="/posts">posts</a>
            </li>
            <li>
              <a href="/projects">projects</a>
            </li>
          </ul>
        </nav>
      </header>
      <div className="intro">
        <div className="intro-wrapper">
          <div className="intro-p">
            <h1>Projects </h1>
            <p></p>
          </div>
          <div className="animation">
            <div>
              <Bg />
            </div>
            <div className="art-legend">
              <b>Click this art</b> to regenerate it.{" "}
              <button>
                [<b>?</b>]
              </button>
              <div className="tooltip">
                This art is randomly generated on every page refresh. You can
                also regenerate it by clicking on it. Every art is brand new!
              </div>
            </div>
          </div>
        </div>
        <div className="dots"></div>
      </div>
      <div className="content">
        <h2 className="with-under">2023</h2>
        <ul className="listing table projects">
          <li>
            <a
              href="https://github.com/varinderxyz/example-m-e-v"
              target="_blank"
              rel="noopener noreferrer"
            >
              Experimental MEV Bot
            </a>
            <div>Maximum Extractable value bot on etherium</div>
          </li>
        </ul>
        <h2 className="with-under">2022</h2>
        <ul className="listing table projects">
          <li>
            <a
              href="https://github.com/varinderxyz/honey-road-frontend"
              target="_blank"
              rel="noopener noreferrer"
            >
              Honeyroad
            </a>
            <div>
              The Seamless Bitcoin Transaction Demonstration Platform that
              highlights the ease and efficiency of using Bitcoin for seamless
              transactions.
            </div>
          </li>
        </ul>
        <h2 className="with-under">2020</h2>
        <ul className="listing table projects">
          <li>
            <a
              href="http://guitarjedi.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Guitarjedi
            </a>
            <div>
              Dynamic chord recognition/generation for any musical instrument
              with n-strings in any tuning.
            </div>
          </li>
        </ul>
        <ul className="listing table projects">
          <li>
            <a
              href="https://github.com/varinderxyz/nouvelle"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nouvelle
            </a>
            <div>Barter, Gig Marketplace</div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Projects
